import React from "react"
import Seo from "../../components/seo"
import Trusted from "../../components/trusted"
import Contacts from "../../components/contacts"
import Glance from "../../components/glance"
import { useState, useContext } from "react"
import TryPdf from "../../components/shared/TryPdf"
import { GlobalAuthContext } from "../../Contexts/GlobalAuthContext"
import Edit from "../../components/edit"

const FeaturesPage = ({ location }) => {
  //global context
  const { setScroll } = useContext(GlobalAuthContext)
  const [myPopUp, setMyPopUp] = useState("hide")

  //close popup function
  function closePopup() {
    setMyPopUp("hide")
    setScroll("hide")
  }
  //open popup function
  function openPopup(e) {
    e.preventDefault()
    setMyPopUp("show")
    setScroll("show")
  }

  return (
    <>
      <Seo title="PDF Editor Free Download" />
      <Glance open={openPopup} location={""}></Glance>
      <Edit></Edit>

      <Trusted></Trusted>
      <Contacts></Contacts>
      {myPopUp === "show" && <TryPdf close={closePopup} />}
    </>
  )
}

export default FeaturesPage
